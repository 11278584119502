.carousel-outer{position: relative;}
.carousel-control{top: calc(50% - 25px); position: absolute !important; z-index: 100; box-shadow: none !important; 
    border-radius: 0; opacity: 0.95;}
.left-carousel-control{left: 10px;}
.right-carousel-control{right: 10px;}
.inside .alice-carousel__dots{position: absolute; bottom: 20px; width: 100%;}
.alice-carousel__dots{margin: 10px 0}
.alice-carousel__stage-item *{line-height: 24px}
.alice-carousel__stage-item {vertical-align: middle}
@media(max-width:767.99px){
    .main-slider .alice-carousel__stage-item img{height: 480px; width: auto !important}
}
/* .alice-carousel__stage-item{max-width: 120px;} */

.slider-content{position: absolute; top:0; left:0; width: 100%; height: 100%;}