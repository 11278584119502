@font-face {
    font-family: 'Coronet';
    src: url('./fonts/Coronet.woff2') format('woff2'),
    url('./fonts/Coronet.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}    

.logo-text {
    font-family: "Coronet";
    font-size: 36px;
    line-height: 36px;
}

.header{box-shadow: 0 1px 0 rgba(0,0,0,0.05)}
.list-inline-item:not(:last-child){margin-right: .8rem}
.form-control{height: calc(1.5em + .75rem + 12px)}
.breadcrumb{padding:0}
.breadcrumb-item.active {
    color: #838d96;
}

/* placeholder color */
.form-control::-webkit-input-placeholder {
    color: #ccc;
}
.form-control:-ms-input-placeholder {
    color: #ccc;
}
.form-control::placeholder {
    color: #ccc;
}

.navbar-collapse .dropdown-menu{margin:0; background: none; border: none}
.dropdown-item{padding: 6px 10px}
.has-arrow{position: relative;}
.has-arrow:after{content:""; position: absolute; z-index: 5; border-color: transparent; border-style: solid}

.with-bg {
    color: #fff;
    background-size: cover;
    background-position: center top;
    position: relative;
}

.with-bg:after{content:""; position: absolute; top:0; left:0; width:100%; height:100%}
.with-overlay{position: relative;}
.with-overlay>*{position: relative; z-index: 1;}
.with-overlay:after{content:""; position: absolute; top:0; left:0; width:100%; height:100%; z-index: 0;}

.breadcrumb{background: none; margin:0}

.list-styled-icon {
    list-style: none;
    padding-left: 22px;
}

.list-styled-icon li:before {
    font-family: 'FontAwesome';
    margin: 0 5px 0 -22px;
    vertical-align: top;
}
.card a{text-decoration: none;}

.footer-navigation-list li:before {
    content: "\f105";
}

.list-styled-icon li.phone:before {
    content: "\f095";
}

.list-styled-icon li.email:before {
    content: "\f0e0";
}

/* page */
img.left,img.right {
    padding: .25rem;
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: .25rem;
    max-width: 100%;
    height: auto;
}
img.left{margin: 0 15px 15px 0; float: left;}
img.right{margin: 0 0 15px 15px; float: right;}

footer li a{display:inline-block}

footer hr{    
    border-color: rgba(255,255,255,0.15);
}

footer .social-icons a {
    background-color: rgba(255,255,255,0.15);
    display: inline-block;
    width: 40px;
    height: 40px;
    text-align: center;
    border-radius: 50%;
    line-height: 40px;
    font-size: 16px;
}

.copyright {
    border-top: 1px solid rgba(255,255,255,0.15);
    margin-top: 30px;
    padding-top: 30px;
}

.powered-by,.powered-by:hover{text-decoration: underline; color: #fff;}

@media(min-width:768px){
    .navbar-collapse li.dropdown:hover>.dropdown-menu{display: block;}
}